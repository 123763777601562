<script setup>
import { ref, onMounted, onUnmounted } from "vue";
import ConfirmationModal from "@/Components/ConfirmationModal.vue";
import PrimaryButton from "@/Components/PrimaryButton.vue";
import { router } from "@inertiajs/vue3";

let showModal = ref(false);
let checking = ref(null);

onMounted(() => {
    checking = setInterval(() => checkIfLogged(), 600000);
});

onUnmounted(() => {
    clearInterval(checking);
});

const checkIfLogged = () => {
    axios.get("/user/checkIfLogged")
        .then((res) => {
            showModal.value = false;
        }).catch((err) => {
            showModal.value = true;
            clearInterval(checking);
        });
};

const reload = () => {
    router.visit("/login");
};
</script>

<template>
    <ConfirmationModal :show="showModal" max-width="xl">
        <template #title>
            {{ __('You have been disconnected') }}
        </template>
        <template #content>
            {{ __('You have been disconnected from the application. Please log in again.') }}
        </template>
        <template #footer>
            <PrimaryButton @click="reload">
                {{ __('Log in') }}
            </PrimaryButton>
        </template>
    </ConfirmationModal>
</template>
